import {post} from "../xhr";

export function add(salaryId: number, requestData: {month: number, year: number, week: number, value: number, maj: string}){
    return post(`paid/add/${salaryId}`, requestData)
}

export function paidValue(salaryId: number, requestData: {month: number, year: number, value: number}){
    return post(`paid/add/value/${salaryId}`, requestData)
}


export function paidLow(salaryId: number, requestData: {month: number, year: number, value25: number}){
    return post(`paid/add/value_low_majoration/${salaryId}`, requestData)
}

export function paidHigh(salaryId: number, requestData: {month: number, year: number, value50: number}){
    return post(`paid/add/value_high_majoration/${salaryId}`, requestData)
}