import React from "react";
import * as models from "../../../../models/synthesis";
import * as reportModels from "../../../../models/report";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import timeFormat from "../../../../utils/timeFormat"
import * as actions from "../../../../actions/synthesis";
import {Link} from "react-router-dom";
import onMouseEnterTooltip from "../../../../utils/mouseOverTooltip";
import {Company} from "../../../../models/companies";

const SynthesisPaidTable:React.FC<{company?: Company, setMaj: React.Dispatch<any>}> = (props) => {
    const dispatch = useDispatch();
    const state: models.initialStateInterface = useSelector((state: RootStateOrAny) => state.synthesis);

    return <>
        <table className="table text-nowrap sticky-table table-striped align-middle text-center">
            <thead>
            <tr>
                <th>
                    Collaborateur
                </th>
                <th>
                    Compteur initial
                </th>
                <th>
                    Total validé
                </th>
                <th>
                    H. payées taux normal
                </th>
                <th style={{backgroundColor: "#9ec5fe"}}>
                    H. payées 25%
                </th>
                <th style={{backgroundColor: "#a3cfbb"}}>
                    H. payées 50%
                </th>
                <th title={"Compteur initial + Compteur période - H. payées = Compteur final."} onMouseEnter={e => onMouseEnterTooltip(e.currentTarget)}>
                    Compteur final <i className={'bi bi-info-circle'}></i>
                </th>
                <th>
                    H. taux normal
                </th>
                <th style={{backgroundColor: "#9ec5fe"}}>
                    HS. 25%
                </th>
                <th style={{backgroundColor: "#a3cfbb"}}>
                    HS. 50%
                </th>
            </tr>
            </thead>
            <tbody>
            {state.payload.map((s) => {
                let updating = !!state.updating.find(u => u.id === s.id);

                return <>
                    <tr key={s.id}>
                        <td>
                            <div className="d-flex align-items-center w-100">
                                <Link target={"_blank"} to={`/salary/${s.salary.id}`}
                                      className={'text-primary mb-0 flex-grow-1'}>
                                    <i className="bi bi-box-arrow-up-right"></i> {s.salary.title}
                                </Link>
                                <button title={"Détail par jour"} onMouseEnter={e => onMouseEnterTooltip(e.target)}
                                        className="btn bg-primary text-white flex-grow-0" data-bs-toggle="modal"
                                        data-bs-target="#synthesisSignature"
                                        onClick={() => dispatch(actions.showSynthesisSuccess(s))}>
                                    <i className={'bi bi-list-ul'}></i>
                                </button>
                            </div>
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <>{!!s.initialCounter && <i className={s.initialCounter < 0 ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}></i>} {timeFormat(s.initialCounter, 'hour', false)}</>}
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : timeFormat(s.counterSaved, 'hour', true)}
                        </td>
                        <td className={'placeholder wave'}>
                            <span>
                                {timeFormat(s.paid)}
                            </span>
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#9ec5fe"}}>
                            <span>
                                {timeFormat(s.paidLowMajoration)}
                            </span>
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#a3cfbb"}}>
                            <span>
                                {timeFormat(s.paidHighMajoration)}
                            </span>
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <>
                                {!!s.finalCounter &&
                                    <i className={s.finalCounter < 0 ? 'bi bi-arrow-down-circle text-danger' : 'bi bi-arrow-up-circle text-success'}></i>} {timeFormat(s.finalCounter, 'hour', false)}
                            </>}
                        </td>
                        <td className={'placeholder wave'}>
                            {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>{timeFormat(s.overtime)}</span>}
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#9ec5fe"}}>
                            {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>{timeFormat(s.overtime25)}</span>}
                        </td>
                        <td className={'placeholder wave'} style={{backgroundColor: "#a3cfbb"}}>
                            {updating ? <div className="line w-100"></div> : <span className={'text-nowrap'}>{timeFormat(s.overtime50)}</span>}
                        </td>
                    </tr>
                </>
            })}
            </tbody>
        </table>
    </>
}

export default SynthesisPaidTable;