import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getMonthOption} from "../../../constants/global";
import * as actions from "../../../actions/synthesis";
import * as api from "../../../adapters/report";
import * as paidApi from "../../../adapters/paid";
import * as reportModels from "../../../models/report";
import * as synthesisModels from "../../../models/synthesis";
import SmallLoader from "../../../utils/loader/small";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import FormError from "../../../utils/formError";
import {Offcanvas} from "bootstrap";
import timeFormat from "../../../utils/timeFormat";
import moment from "../../../utils/moment.utils";

const ReportPaid: React.FC<{ maj: "none"|"low"|"high" }> = ({maj}) => {
    const dispatch = useDispatch();
    const report:reportModels.Report  = useSelector((state: RootStateOrAny) => state.report.single);
    const synthesis:synthesisModels.Synthesis|undefined  = useSelector((state: RootStateOrAny) => state.synthesis.single);
    const weeksOptions = synthesis ? Object.values(synthesis.weeks).filter(el => !el.overlapEnd).map(week => ({
        label: `S.${week.weekNumber} - Du ${moment(week.start).format('DD/MM/YY')} au ${moment(week.end).format('DD/MM/YY')}`,
        value: Number(week.weekNumber)
      })) : [];
    const [hour, setHour] = useState<number>(0);
    const [min, setMin] = useState<number>(0);

    const [offcanvas, setOffcanvas] = useState<Offcanvas>()

    useEffect(() => {
        const el = document.getElementById('offcanvasReportPaid')! as HTMLDivElement;
        setOffcanvas(new Offcanvas(el));
    }, [])

    function close(){
        if (!offcanvas) return;
        offcanvas.hide();
    }

    const [initialValues, setInitialValues] = useState<{ week: number, value: number }>({
        week: weeksOptions.length > 0 ? weeksOptions[0].value : 0,
        value: Math.max(synthesis?.paid || 0, 0),
    });

    useEffect(() => {
        if (synthesis){
            let paid = synthesis.paid;
            switch (maj){
                case "low":
                    paid = synthesis.paidLowMajoration;
                    break;
                case "high":
                    paid = synthesis.paidHighMajoration;
                    break;
            }

            setInitialValues(p => ({
                week: weeksOptions[0].value,
                value: Math.max(paid, 0)
            }))

            let time = Math.max(paid, 0);

            const hours = Math.floor(time / 3600);
            const minutes = Math.floor((time - (hours * 3600)) / 60) ;

            setHour(hours)
            setMin(minutes)

            // let time25 = Math.max(synthesis?.paidLowMajoration || 0, 0);
            //
            // const hours25 = Math.floor(time25 / 3600);
            // const minutes25 = Math.floor((time25 - (hours25 * 3600)) / 60) ;
            //
            // setHour25(hours25)
            // setMin25(minutes25)
            //
            // let time50 = Math.max(synthesis?.paidHighMajoration || 0, 0);
            //
            // const hours50 = Math.floor(time50 / 3600);
            // const minutes50 = Math.floor((time50 - (hours50 * 3600)) / 60) ;
            //
            // setHour50(hours50)
            // setMin50(minutes50)
        }
    }, [synthesis, maj])

    function handleOvertimeClick(){
        if (synthesis){
            let overtime = synthesis.overtime;

            switch (maj){
                case "low":
                    overtime = synthesis.overtime25;
                    break;
                case "high":
                    overtime = synthesis.overtime50;
                    break;
            }

            let time = Math.max(overtime || 0, 0);

            const hours = Math.floor(time / 3600);
            const minutes = Math.floor((time - (hours * 3600)) / 60) ;

            setHour(hours)
            setMin(minutes)
        }
    }

    useEffect(() => {
        setInitialValues({ week: initialValues.week, value: (hour || 0) * 3600 + (min || 0) * 60 })
    }, [min, hour])

    const validationSchema = Yup.object().shape({
        week: Yup.number().required(),
        value: Yup.number().required(),
    });

    const formik = useFormik({
       initialValues: initialValues,
       validationSchema: validationSchema,
       enableReinitialize: true,
       onSubmit: (values, formikHelpers) => {
           paidApi.add(synthesis!.id, { year: report.year, month: report.month, week: values.week, value: values.value, maj: maj })
               .then(() => dispatch(actions.updateSyntheses([synthesis!])))
               .then(() => close())
               .then(() => formikHelpers.setSubmitting(false))
       }
    });

    return <>
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasReportPaid"
             aria-labelledby="offcanvasReportPaidLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasReportPaidLabel">Renseigner les heures payées de {synthesis?.salary.title} de {getMonthOption(String(report.month))?.label} {report.year}</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <form className={"d-flex flex-column h-100"} onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-12 d-flex align-items-center mb-3">
                            <h4 className={'flex-grow-1'}>
                                Heures
                                {maj === "none" && "taux normal"}
                                {maj === "low" && "majorées 25%"}
                                {maj === "none" && "majorées 50%"}
                            </h4>
                            {!!synthesis?.overtime && <button type={"button"} onClick={() => handleOvertimeClick()} className={'btn btn-light'}>
                                Payer {maj === "none" && timeFormat(synthesis.overtime)}{maj === "low" && timeFormat(synthesis.overtime25)}{maj === "high" && timeFormat(synthesis.overtime50)}
                            </button>}
                            {(!!hour || !!min) && <button type={"button"} onClick={() => {
                                setHour(0)
                                setMin(0)
                            }} className={'btn btn-light ms-2'}>
                                <i className={'bi bi-dash text-danger'}></i> Retirer
                            </button>}
                        </div>
                        <div className="col-6">
                            <BootstrapSelect
                                key={hour}
                                label={"Heures"}
                                required={true}
                                value={{label: String(hour || 0), value: (hour || 0)}}
                                options={Array.from(Array(250).keys()).map((n) => ({label: String(n), value: n}))}
                                onChange={(c) => setHour(Number(c?.value))}
                                enableReinitialize={true}
                            />
                        </div>
                        <div className="col-6">
                            <BootstrapSelect
                                key={min}
                                label={"Minutes"}
                                required={true}
                                value={{label: String(min || 0), value: (min || 0)}}
                                options={Array.from(Array(59).keys()).map((n) => ({label: String(n), value: n}))}
                                onChange={(c) => setMin(Number(c?.value))}
                                enableReinitialize={true}
                            />
                        </div>
                        <FormError errors={formik.errors} touched={formik.touched} field={'value'} />
                    </div>
                    <button disabled={formik.isSubmitting || !synthesis} type={'submit'} className={'btn btn-light w-100 flex-grow-0'}>
                        {formik.isSubmitting ? <><SmallLoader /></> : <><i className={'bi bi-check text-success'}></i> Valider</>}
                    </button>
                </form>
            </div>
        </div>
    </>
}

export default ReportPaid;